import { useState, useCallback, useEffect } from "react";

/**
 * Returns the current window size.
 * @param {number} initialWidthReference Serves as the initial state
 *                                       of the width. Defaults as 0.
 * @param {number} initialHeightReference Serves as the initial state
 *                                        of the height. Defaults as 0.
 * @return {number}
 */
export const useWindowSize = (
  initialWidthReference = 0,
  initialHeightReference = 0
) => {
  const [width, setWidth] = useState(initialWidthReference);
  const [height, setHeight] = useState(initialHeightReference);

  const handleWindowSize = useCallback(() => {
    if (typeof window != "undefined") {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
  }, [setWidth, setHeight]);

  useEffect(() => {
    handleWindowSize();
    const resizeEvent = window.addEventListener("resize", () => {
      handleWindowSize();
    });
    return () => {
      window.removeEventListener("resize", resizeEvent);
    };
  }, [handleWindowSize]);

  return { width, height };
};
