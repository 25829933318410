import Sprite from "app/components/sprite";
import { Trans } from "react-i18next";
import classNames from "classnames";
import PropTypes from "prop-types";
import { ZBNI_WEBSITE_URL } from "app/config/constants";

const DashboardLoginButton = ({
  id = "cta_dashboardLogin",
  iconColor = "#fff",
  useShortDisplay,
  additionalClassNames,
}) => {
  return (
    <a
      id={id}
      href={`${ZBNI_WEBSITE_URL}/dashboard/login`}
      rel="noreferrer"
      target="_blank"
      className={classNames(
        "flex items-center justify-center gap-2",
        additionalClassNames
      )}
    >
      <Sprite name="user" fill={iconColor} className="h-5 w-5" />
      <p className="whitespace-nowrap font-bold">
        <span>
          {useShortDisplay ? (
            <Trans>Login</Trans>
          ) : (
            <Trans>Dashboard login</Trans>
          )}
        </span>
      </p>
    </a>
  );
};

export default DashboardLoginButton;

DashboardLoginButton.propTypes = {
  id: PropTypes.string,
  iconColor: PropTypes.string,
  useShortDisplay: PropTypes.bool,
  additionalClassNames: PropTypes.string,
};
