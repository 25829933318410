import { MobileNavDropdown } from "app/components/mobile-dropdown";
import { NavDropdown } from "app/components/nav-dropdown";
import { DesktopNavLink } from "app/components/desktop-nav-link";
import { Link, NavLink as RemixNavLink } from "@remix-run/react";
import Sprite from "app/components/sprite";
import externalLink from "app/images/external-link.png";
import { isPathExcluded } from "app/utils/path";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  useI18nLanguage,
  useOriginalPathname,
  useLocalizePathname,
} from "app/hooks/i18n";
import { ZBNI_CSHOP_MARKET_URL, ZBNI_HUB_URL } from "app/config/constants";

// NOTE: Should be for the main header only!
export const headerRoutes = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t: trans } = useTranslation();
  const routes = {
    home: {
      id: "cta_header_zbooniLogo_in",
      url: "/",
    },
    main: [
      {
        id: "cta_header_features_in", // url tag id
        current: ["/features"], // basis for current page
        display: trans("Features"), // content displayed,
        hash: "#details",
        sublinks: [
          {
            id: "cta_header_features_captureOrders_in",
            url: "/features/capture-orders/",
            label: trans("Capture Orders"),
          },
          {
            id: "cta_header_features_acceptPayments_in",
            url: "/features/accept-payments/",
            label: trans("Accept Payments"),
          },
          {
            id: "cta_header_features_sellMore_in",
            url: "/features/sell-more/",
            label: trans("Sell More"),
          },
          {
            id: "cta_header_features_trackEverything_in",
            url: "/features/track-everything/",
            label: trans("Track Everything"),
          },
        ],
      },
      {
        id: "cta_header_whoWeServe_in",
        url: "/who-we-serve/",
        current: "/who-we-serve",
        display: trans("Who We Serve"),
      },
      {
        id: "cta_header_pricing_in",
        url: "/pricing/all-in-one/",
        current: "/pricing",
        display: trans("Pricing"),
      },
      {
        id: "cta_header_zbooniHub_in",
        current: ["/blog", "/partnerships", "/case-studies"],
        display: trans("Zbooni Hub"),
        sublinks: [
          {
            id: "link_header_zbooni_hub",
            url: ZBNI_HUB_URL,
            label: trans("Knowledge Base"),
            icon: externalLink,
            external: true,
            dataGtmAttributes: {
              "data-gtm-source": "web",
            },
          },
          {
            id: "cta_header_blog_in",
            url: "/blog",
            label: trans("Blogs"),
            // will do document reload instead of client side navigation
            reloadDocument: true,
          },
          {
            id: "cta_header_partnerships_in",
            url: "/partnerships",
            label: trans("Partnerships"),
          },
          {
            id: "cta_header_caseStudies_in",
            url: "/case-studies",
            label: trans("Case Studies"),
          },
        ],
      },
      {
        id: "cta_header_marketplace_in",
        url: `${ZBNI_CSHOP_MARKET_URL}?utm_source=website&utm_medium=zbooni_web_primary_nav&utm_campaign=zbooni_cshop&utm_content=nav_zbooni_website`,
        display: trans("cShop Market"),
        icon: externalLink,
        external: true,
      },
    ],
  };

  return routes;
};

export const ZbooniLogo = ({ headerIsOnTop }) => {
  const { locale: language } = useI18nLanguage();
  const routes = headerRoutes();

  return (
    <Link to={routes.home.url} id={routes.home.id}>
      <Sprite
        name={`zbooni-${language}`}
        className={classNames(
          "h-10 w-[135px] xl:transition-all xl:duration-300 xl:ease-in",
          headerIsOnTop
            ? "xl:h-12 xl:w-[180px] ltr:xl:w-64 rtl:xl:w-52"
            : "xl:w-[154px]"
        )}
      />
    </Link>
  );
};

export const LanguageToggle = ({ className }) => {
  const originalPath = useOriginalPathname();
  const { locale, nextLocale } = useI18nLanguage();
  const { nextLocalizePath } = useLocalizePathname();

  return [
    !isPathExcluded(originalPath) && (
      <Link
        key="Languagetoggllink"
        className={className}
        to={nextLocalizePath(originalPath)}
        language={nextLocale}
        reloadDocument
      >
        <span lang={nextLocale}>{locale === "en" ? "AR" : "EN"}</span>
      </Link>
    ),
  ];
};

export const DesktopRoutes = ({ headerIsOnTop }) => {
  const routes = headerRoutes();

  return (
    <>
      {routes.main.map(
        (
          { id, url, display, hash, current, sublinks, external, icon },
          index
        ) => {
          if (sublinks) {
            return (
              <NavDropdown
                key={`NavDropdown-${index}`}
                sublinks={sublinks}
                headerIsOnTop={headerIsOnTop}
                current={current}
                display={display}
                hash={hash}
              />
            );
          }

          return (
            <DesktopNavLink
              key={`navlink-${index}`}
              current={current}
              to={url}
              id={id}
              external={external}
              reloadDocument
            >
              <span
                className={classNames({
                  "ltr:pr-5 rtl:pl-5": icon,
                })}
              >
                {display}
              </span>
              {icon && (
                <div
                  className={classNames(
                    "absolute ltr:right-0 rtl:left-0",
                    headerIsOnTop ? "top-0.5" : "top-0"
                  )}
                >
                  <img
                    alt=""
                    src={icon}
                    className="w-3.5 brightness-0 invert group-hover:brightness-100 group-hover:invert-0"
                  />
                </div>
              )}
            </DesktopNavLink>
          );
        }
      )}
    </>
  );
};

export const MobileRoutes = () => {
  const routes = headerRoutes();

  return (
    <>
      {routes.main.map(
        (
          { id, url, display, current, sublinks, external, icon, hash },
          index
        ) => {
          if (sublinks) {
            return (
              <MobileNavDropdown
                key={`MobileNavDropdown-${index}`}
                sublinks={sublinks}
                current={current}
                display={display}
                hash={hash}
              />
            );
          }

          return (
            <div
              className="bg-gradient-to-b from-yellowgreen to-cyan-dark"
              key={`mobile-main-route-${index}`}
            >
              <RemixNavLink
                to={url}
                id={id}
                className={({ isActive }) =>
                  classNames(
                    "block border-b border-b-primary-light bg-primary px-6 py-[18px] text-sm hover:w-[99%] hover:border-b-secondary-dark hover:bg-secondary-dark",
                    isActive ? "mobile-active-btn" : ""
                  )
                }
                end
                target={external ? "_blank" : "_self"}
              >
                {display}
                {icon && (
                  <img
                    alt=""
                    src={icon}
                    className="inline w-3.5 brightness-0 invert ltr:ml-2.5 rtl:mr-2.5"
                  />
                )}
              </RemixNavLink>
            </div>
          );
        }
      )}
      <div className="group bg-gradient-to-b from-yellowgreen to-cyan-dark">
        <LanguageToggle className="block border-b border-b-primary-light bg-primary px-6 py-[18px] text-sm hover:border-b-secondary-dark group-hover:w-[99%] group-hover:bg-secondary-dark" />
      </div>
    </>
  );
};

DesktopRoutes.propTypes = {
  headerIsOnTop: PropTypes.bool,
};

ZbooniLogo.propTypes = {
  headerIsOnTop: PropTypes.bool,
};

LanguageToggle.propTypes = {
  className: PropTypes.string,
};
