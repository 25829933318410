import { useI18nLanguage } from "app/hooks/i18n/useI18nLanguage";
import config from "app/config/i18n";

export function useLocalizePathname() {
  const { locale, nextLocale } = useI18nLanguage();

  function localizePath(path) {
    return `/${locale}${path}`;
  }

  function nextLocalizePath(path) {
    if (nextLocale === config.fallbackLng) return path;
    return `/${nextLocale}${path}`;
  }

  return { localizePath, nextLocalizePath };
}
