import SubLinks from "app/components/sublinks";
import { useOriginalPathname } from "app/hooks/i18n";
import CaretDown from "app/images/svgs/caret-down.svg";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

export const NavDropdown = ({
  sublinks,
  headerIsOnTop,
  current,
  display,
  hash,
}) => {
  const locationPathname = useOriginalPathname();
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const isPathActive = current.some((pathname) =>
    locationPathname.includes(pathname)
  );

  return (
    <div
      className="relative"
      onMouseEnter={() => setDropdownOpen(true)}
      onMouseLeave={() => setDropdownOpen(false)}
    >
      <div
        className={classNames(
          "cursor-pointer from-yellowgreen to-cyan-dark hover:bg-gradient-to-r hover:bg-clip-text hover:text-transparent",
          isDropdownOpen || isPathActive
            ? "bg-gradient-to-r bg-clip-text text-transparent"
            : "brightness-0 invert"
        )}
      >
        <div
          className={classNames("inline pb-[25px]", {
            "decoration-yellow-green-cyan-underline": isPathActive,
          })}
        >
          <span className="ltr:pr-5 rtl:pl-5">{display}</span>
          <div
            className={classNames(
              "absolute",
              headerIsOnTop
                ? "top-2 ltr:right-0 rtl:left-0"
                : "top-1 ltr:right-1 rtl:left-1"
            )}
          >
            <img src={CaretDown} alt="" />
          </div>
        </div>
      </div>
      {isDropdownOpen && (
        <Fragment>
          <SubLinks
            hash={hash}
            isDropdownOpen={isDropdownOpen}
            sublinks={sublinks}
          />
        </Fragment>
      )}
    </div>
  );
};

NavDropdown.propTypes = {
  sublinks: PropTypes.array,
  headerIsOnTop: PropTypes.bool,
  current: PropTypes.array,
  display: PropTypes.string,
  hash: PropTypes.string,
};
