import { Trans } from "react-i18next";
import classNames from "classnames";
import PropTypes from "prop-types";

const DownloadAppButton = ({
  id = "cta_downloadApp",
  additionalClassNames,
  onClick,
}) => {
  return (
    <button
      id={id}
      className={classNames(
        "yellow-green-cyan-btn whitespace-nowrap rounded-full text-center font-bold text-primary",
        additionalClassNames
      )}
      onClick={onClick}
    >
      <Trans>Download App</Trans>
    </button>
  );
};

export default DownloadAppButton;

DownloadAppButton.propTypes = {
  id: PropTypes.string,
  additionalClassNames: PropTypes.string,
  onClick: PropTypes.func,
};
