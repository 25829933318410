import { AnimationObserverProvider } from "app/components/animations/animation-observer-provider";
import Footer from "app/components/footer";
import Header from "app/components/header";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Layout = ({
  children,
  headerIsOnTop = true,
  enableHeaderShrinkingOnScroll = true,
}) => {
  return (
    <AnimationObserverProvider>
      <ToastContainer position="bottom-center" autoClose={1500} limit={1} />
      <div className="flex h-screen flex-col">
        <div>
          <Header
            isOnTop={enableHeaderShrinkingOnScroll ? headerIsOnTop : false}
          />
        </div>
        <div className="flex-1 text-base">
          <main>{children}</main>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </AnimationObserverProvider>
  );
};

Layout.propTypes = {
  headerIsOnTop: PropTypes.bool,
  enableHeaderShrinkingOnScroll: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Layout;
