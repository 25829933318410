import DashboardLoginButton from "app/components/buttons/dashboard-login";
import DownloadAppButton from "app/components/buttons/download-app";
import RequestDemoButton from "app/components/buttons/request-demo";
import GetZbooniPopover from "app/components/get-zbooni-popover";
import {
  DesktopRoutes,
  LanguageToggle,
  ZbooniLogo,
} from "app/components/header-routes";
import { MobileMenu } from "app/components/mobile-menu";
import Sprite from "app/components/sprite";
import { ZBNI_CUSTOMER_SUPPORT } from "app/config/constants";
import { useOriginalPathname } from "app/hooks/i18n";
import { useWindowSize } from "app/hooks/useWindowSize";
import { isPathExcluded } from "app/utils/path";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import tailwindConfig from "tailwindcss/defaultTheme";

// add phases if needed
const phases = {
  default: "h-16 bg-primary animate__animated fade-in-slide-down",
  mobile: "py-3 bg-primary",
  top: "h-32",
};

const Header = ({ isOnTop = true }) => {
  const originalPath = useOriginalPathname();
  const [popover, setPopover] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const initialPhase = isOnTop ? "top" : "default";
  const [phase, setPhase] = useState(initialPhase);
  const [isInitial, setIsInitial] = useState(true);

  const xl = parseInt(tailwindConfig.screens.xl.replace("px", ""));

  const { width: windowWidth } = useWindowSize(xl);

  // NOTE: This effect is for syncing the phase
  // consistently when the window size changes.
  useEffect(() => {
    if (windowWidth >= xl) {
      if (isOnTop) {
        setPhase("top");
      } else {
        setPhase("default");
      }
    } else {
      setPhase("mobile");
    }
  }, [windowWidth]);

  const phaseConfigs = useMemo(() => {
    if (isOnTop) {
      // These are like keyframes of phase change.
      return [
        {
          phase: "top",
          time: 0,
        },
      ];
    }
    return [
      {
        phase: "default",
        time: 10,
      },
    ];
  }, [isOnTop, windowWidth]);

  useEffect(() => {
    if (windowWidth < xl) {
      // force mobile if windowWidth is less than xl
      setPhase("mobile");
      return;
    }
    if (!isInitial) {
      const timeouts = [];
      for (const { phase, time } of phaseConfigs) {
        const timeout = setTimeout(() => {
          setPhase(phase);
        }, time);
        timeouts.push(timeout);
      }
      return () => {
        for (const timeout of timeouts) {
          clearTimeout(timeout);
        }
      };
    } else {
      setIsInitial(false);
    }
  }, [phaseConfigs, setPhase, setIsInitial]);

  const phaseCss = useMemo(() => phases[phase], [phase]);
  const zbooniLink = `${ZBNI_CUSTOMER_SUPPORT}&text=Hello%21%20I%20am%20interested%20in%20learning%20more%20about%20Zbooni.`;

  return (
    <header className="relative font-montserrat text-base text-white">
      <GetZbooniPopover
        popover={popover}
        setPopover={setPopover}
        zbooniLink={zbooniLink}
      />
      <nav
        className={classNames(
          "fixed left-0 right-0 z-50 flex lg:transition-all lg:duration-300 lg:ease-in",
          phaseCss
        )}
      >
        <div className="zbooni-layout flex w-full items-center">
          <div className="flex flex-grow items-center">
            <div className="flex w-full items-center justify-between">
              <ZbooniLogo headerIsOnTop={isOnTop} />
              <div className="-mr-2 flex items-center xl:hidden">
                <button
                  onClick={() => setIsCollapsed(!isCollapsed)}
                  type="button"
                  className="inline-flex items-center justify-center p-2"
                >
                  {!isCollapsed && (
                    <Sprite
                      name="navbar-burger"
                      className="h-6 w-[24px] rtl:scale-x-[-1]"
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
          <div
            className={classNames(
              "hidden items-center gap-6 font-bold transition-all duration-300 ease-in xl:flex",
              phase == "top" ? "gap-x-5 text-sm xl:gap-x-7" : "gap-x-4 text-xs"
            )}
          >
            <DesktopRoutes headerIsOnTop={phase == "top"} />
            <div
              className={classNames("h-4 w-px bg-secondary-dark", {
                hidden: phase == "top",
              })}
            />
            <DashboardLoginButton
              id="cta_header_dashboardLogin"
              additionalClassNames={classNames(
                phase == "top"
                  ? "rounded-full border-2 lg:px-5 lg:py-2.5 xl:px-6 xl:py-3 xl:order-2"
                  : "border-none"
              )}
              useShortDisplay={phase != "top"}
            />
            <div
              className={classNames("h-4 w-px bg-secondary-dark", {
                hidden: phase == "top" || isPathExcluded(originalPath),
              })}
            />
            <LanguageToggle
              key="desktop-toggle"
              className={classNames({ "xl:order-1": phase == "top" })}
            />
            <div
              className={classNames("flex gap-2.5 text-xs", {
                hidden: phase == "top",
              })}
            >
              <DownloadAppButton
                id="cta_header_downloadApp"
                additionalClassNames="w-36 py-2"
                onClick={() => setPopover(true)}
              />
              <RequestDemoButton
                id="cta_header_requestDemo"
                additionalClassNames="w-36 bg-white py-2"
              />
            </div>
          </div>
          <MobileMenu
            collapse={isCollapsed}
            onCloseClicked={() => setIsCollapsed(!isCollapsed)}
          />
        </div>
      </nav>
    </header>
  );
};

Header.propTypes = {
  isOnTop: PropTypes.bool,
};

export default Header;
