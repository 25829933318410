import Sprite from "app/components/sprite";
import SubLinks from "app/components/sublinks";
import { useOriginalPathname } from "app/hooks/i18n";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

export const MobileNavDropdown = ({ sublinks, current, display, hash }) => {
  const originalPath = useOriginalPathname();
  const isPathActive = current.some((pathname) =>
    originalPath.includes(pathname)
  );
  const [isDropdownOpen, setDropdownOpen] = useState(isPathActive);

  return (
    <Fragment>
      <button
        onClick={() => setDropdownOpen((prev) => !prev)}
        className="flex w-full justify-between border-b border-b-primary-light bg-primary px-6 py-[18px] text-left text-sm hover:border-b-secondary-dark hover:bg-secondary-dark"
      >
        <span
          className={classNames(
            isDropdownOpen || isPathActive
              ? "bg-gradient-to-r from-yellowgreen to-cyan-dark bg-clip-text text-transparent"
              : "text-white"
          )}
        >
          {display}
        </span>
        <Sprite
          name="caret-down"
          className={classNames(
            "h-5 w-5 brightness-0 invert transition",
            isDropdownOpen ? "rotate-0" : "ltr:-rotate-90 rtl:rotate-90"
          )}
        />
      </button>
      <SubLinks
        isDropdownOpen={isDropdownOpen}
        sublinks={sublinks}
        hash={hash}
        NavLinkProps={{
          reloadDocument: true,
        }}
      />
    </Fragment>
  );
};

MobileNavDropdown.propTypes = {
  sublinks: PropTypes.array,
  current: PropTypes.array,
  display: PropTypes.string,
};
