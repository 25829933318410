/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import DashboardLoginButton from "app/components/buttons/dashboard-login";
import DownloadAppButton from "app/components/buttons/download-app";
import RequestDemoButton from "app/components/buttons/request-demo";
import GetZbooniPopover from "app/components/get-zbooni-popover";
import { MobileRoutes } from "app/components/header-routes";
import Sprite from "app/components/sprite";
import { ZBNI_CUSTOMER_SUPPORT } from "app/config/constants";
import { isRtlLang } from "app/hooks/rtl-detect";
import { useI18nLanguage } from "app/hooks/i18n";
import whatsapp from "app/images/logos/whatsapp.png";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useState } from "react";
import { Trans } from "react-i18next";

const YTLink = "https://www.youtube.com/channel/UCslWxX_PWfH3cioB_CED1Gg";

const Socials = () => (
  <div className="flex flex-1 flex-col justify-evenly gap-y-10 py-12 text-white">
    <a
      href={`${ZBNI_CUSTOMER_SUPPORT}&text=Hello%21%20I%20am%20interested%20in%20learning%20more%20about%20Zbooni.`}
      rel="noreferrer"
      id="cta_header_getInTouch"
    >
      <div className="mx-6 flex items-center">
        <img src={whatsapp} alt="whatsapp" />
        <span className="text-sm font-bold ltr:ml-2 rtl:mr-2">
          <Trans>Get in touch</Trans>
        </span>
      </div>
    </a>
    <div className="mx-6">
      <p className="mb-1 text-xs font-semibold uppercase text-secondary">
        <Trans>Find us on</Trans>
      </p>
      <div className="flex space-x-4 rtl:space-x-reverse xs:space-x-5">
        <div className="col-span-2 ltr:mr-1 rtl:ml-1">
          <a
            href="https://www.facebook.com/ZbooniApp/"
            target="_blank"
            rel="noreferrer"
            id="cta_header_facebook"
          >
            <Sprite name="facebook" fill="#FFFFFF" className="h-10 w-[13px]" />
          </a>
        </div>
        <div className="col-span-2">
          <a
            href="https://twitter.com/zbooni_app"
            target="_blank"
            rel="noreferrer"
            id="cta_header_twitter"
          >
            <Sprite name="twitter" fill="#FFFFFF" className="h-10 w-[29px]" />
          </a>
        </div>
        <div className="col-span-2">
          <a
            href="https://www.instagram.com/zbooni_app/"
            target="_blank"
            rel="noreferrer"
            id="cta_header_instagram"
          >
            <Sprite name="instagram" fill="#FFFFFF" className="h-10 w-[34px]" />
          </a>
        </div>
        <div className="col-span-2">
          <a
            href="https://www.linkedin.com/company/zbooni/"
            target="_blank"
            rel="noreferrer"
            id="cta_header_linkedin"
          >
            <Sprite name="linkedin" fill="#FFFFFF" className="h-10 w-[30px]" />
          </a>
        </div>
        <div className="col-span-2">
          <a
            href={YTLink}
            target="_blank"
            rel="noreferrer"
            id="cta_header_youtube"
          >
            <Sprite name="youtube" fill="#FFFFFF" className="h-10 w-[32px]" />
          </a>
        </div>
      </div>
    </div>
  </div>
);

export const MobileMenu = ({ collapse = false, onCloseClicked }) => {
  const [popover, setPopover] = useState(false);
  const { locale: language } = useI18nLanguage();
  const menuAnimation = isRtlLang(language)
    ? "animate__fadeInRight"
    : "animate__fadeInLeft";

  return (
    <div
      className={classNames(
        "fixed inset-x-0 top-0 z-20 grid grid-flow-row grid-cols-12 bg-white bg-opacity-30 xl:hidden",
        collapse ? "block" : "hidden"
      )}
    >
      <GetZbooniPopover popover={popover} setPopover={setPopover} />
      <div
        className={classNames(
          "animate__animated animate__faster col-span-10 h-screen max-w-sm overflow-y-auto bg-primary text-white md:col-span-5",
          menuAnimation
        )}
      >
        <div className="text-base font-bold text-white">
          <MobileRoutes />
        </div>
        <div className="flex flex-col items-center gap-4 py-10">
          <p className="text-xs text-secondary">
            <Trans>Are you already a merchant on Zbooni?</Trans>
          </p>
          <DashboardLoginButton
            id="cta_mobileMenu_dashboardLogin"
            additionalClassNames={classNames(
              "w-full max-w-[270px] rounded-full border-2 py-3 text-xs"
            )}
          />
        </div>
        <hr className="m-0 mx-6 border-t border-solid border-primary-light" />
        <div className="flex flex-col items-center gap-4 py-7 text-sm">
          <DownloadAppButton
            id="cta_mobileMenu_downloadApp"
            additionalClassNames="w-full max-w-[270px] py-3 font-extrabold"
            onClick={() => setPopover(true)}
          />
          <RequestDemoButton
            id="cta_mobileMenu_requestDemo"
            additionalClassNames="w-full max-w-[270px] bg-white py-3"
            textClassNames="font-extrabold"
          />
        </div>
        <hr className="m-0 mx-6 border-t border-solid border-primary-light" />
        <Socials />
      </div>
      <div
        className="col-span-2 text-center md:col-span-7 ltr:md:mr-5 ltr:md:text-right rtl:md:ml-5 rtl:md:text-left ltr:lg:mr-[52px] rtl:lg:ml-[52px]"
        onClick={onCloseClicked}
      >
        <button
          type="button"
          onClick={onCloseClicked}
          className="mt-4 rounded-full bg-primary p-2 text-white"
        >
          <span className="sr-only">Close main menu</span>
          <Sprite name="cross-x" className="h-[18px] w-[18px]" />
        </button>
      </div>
    </div>
  );
};

MobileMenu.propTypes = {
  collapse: PropTypes.bool,
  onCloseClicked: PropTypes.func.isRequired,
};
