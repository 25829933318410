import { useLocation } from "@remix-run/react";
import { useI18nLanguage } from "app/hooks/i18n/useI18nLanguage";

/**
 * Returns the url path without locale routing
 * @returns {string}
 */
export function useOriginalPathname() {
  const location = useLocation();
  const { locale } = useI18nLanguage();

  let _pathname = location.pathname;

  if (location.pathname.startsWith(`/${locale}`)) {
    _pathname = location.pathname.replace(`/${locale}`, "");
  }

  return _pathname;
}
