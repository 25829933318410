import { NavLink } from "@remix-run/react";
import { useOriginalPathname } from "app/hooks/i18n";
import useLinkHref from "app/utils/href";
import classNames from "classnames";

export const DesktopNavLink = ({
  children,
  to,
  external,
  current,
  ...props
}) => {
  const locationPathname = useOriginalPathname();
  const isPathActive = Array.isArray(current)
    ? current.some((pathname) => locationPathname.includes(pathname))
    : locationPathname?.includes(current);
  const href = useLinkHref(to);

  return (
    <NavLink
      {...props}
      to={external ? to : href}
      className={({ isActive }) =>
        classNames(
          "group relative whitespace-nowrap from-yellowgreen to-cyan-dark hover:bg-gradient-to-r hover:bg-clip-text hover:text-transparent",
          isActive || isPathActive
            ? "bg-gradient-to-r bg-clip-text text-transparent"
            : ""
        )
      }
      target={external ? "_blank" : undefined}
      rel={external ? "noreferrer" : undefined}
    >
      {({ isActive }) => (
        <div
          className={classNames("inline pb-[25px]", {
            "decoration-yellow-green-cyan-underline": isActive || isPathActive,
          })}
        >
          {children}
        </div>
      )}
    </NavLink>
  );
};
