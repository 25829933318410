import DashboardLoginButton from "app/components/buttons/dashboard-login";
import Sprite from "app/components/sprite";
import {
  ZBNI_CSHOP_MARKET_URL,
  ZBNI_CUSTOMER_SUPPORT,
  ZBNI_HUB_URL,
} from "app/config/constants";
import { useI18nLanguage } from "app/hooks/i18n";
import ExternalLinkIcon from "app/images/external-link.png";
import SecureTrustLogo from "app/images/logos/secure-trust.png";
import WhatsAppLogo from "app/images/logos/whatsapp.png";
import Link from "app/components/i18n/Link";
import { Trans, useTranslation } from "react-i18next";
import { Fragment } from "react";

const FooterSection = ({ title, children }) => {
  return (
    <div className="flex flex-col gap-y-3.5">
      <p className="font-semibold uppercase text-secondary ltr:text-10px rtl:text-base tracking-[0.08em]">
        {title}
      </p>
      <div className="flex flex-col gap-y-3 text-sm font-semibold">
        {children}
      </div>
    </div>
  );
};

const SecureTrustSection = () => {
  return (
    <Fragment>
      <div className="flex items-start lg:items-center">
        <img className="inline w-16" src={SecureTrustLogo} alt="secure-trust" />
      </div>
      <div className="flex w-10/12 items-center ltr:text-left rtl:text-right">
        <p className="px-1 inline-block text-11px leading-4 text-secondary">
          <Trans>
            Based upon information provided by zbooni regarding its policies,
            procedures, and technical systems that store, process and/or
            transmit cardholder data, zbooni has performed the required
            procedures to validate compliance with the PCI DSS.
          </Trans>
        </p>
      </div>
    </Fragment>
  );
};
const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  const YTLink = "https://www.youtube.com/channel/UCslWxX_PWfH3cioB_CED1Gg";
  const { locale: language } = useI18nLanguage();
  const { t: trans } = useTranslation();

  return (
    <footer
      className="relative z-10 overflow-hidden bg-primary-semilight text-base text-white"
      lang={language}
    >
      <Sprite
        name="footer-donut"
        fill="#282F3A"
        className="absolute -z-10 w-[1240px] h-[1240px] top-96 translate-y-28 ltr:-right-96 rtl:-left-96 lg:translate-y-0 lg:top-20 ltr:xl:-right-48 rtl:xl:-left-48"
      />
      <div className="zbooni-layout">
        <div className="flex py-8">
          <Link to="/" id="cta_footer_zbooniLogo_in" className="w-auto">
            <Sprite name={`zbooni-${language}`} className="h-10 w-36 xl:w-40" />
          </Link>
        </div>
        <div className="grid grid-flow-row grid-cols-1 lg:grid-cols-12">
          <div className="flex flex-col gap-y-20 col-span-9 text-center pb-8 xl:pb-10 ltr:lg:pl-0 rtl:lg:pl-8 ltr:lg:pr-8 rtl:lg:pr-0 rtl:xl:pl-16 ltr:xl:pr-16">
            <div className="flex flex-col justify-between gap-x-3 gap-y-7 ltr:text-left rtl:text-right lg:flex-row">
              <FooterSection title={trans("Products")}>
                <Link
                  to="/features/capture-orders/"
                  id="cta_footer_features_in"
                >
                  <Trans>Zbooni App (For Business)</Trans>
                </Link>
                <Link
                  to="/features/track-everything/"
                  id="cta_footer_features_trackEverything_in"
                >
                  <Trans>Business Dashboard</Trans>
                </Link>
                <a
                  href={`${ZBNI_CSHOP_MARKET_URL}?utm_source=website&utm_medium=zbooni_web_footer_nav&utm_campaign=zbooni_cshop&utm_content=nav_zbooni_website`}
                  target="_blank"
                  rel="noreferrer"
                  id="cta_footer_marketplace_in"
                  className="flex items-center gap-x-2"
                >
                  <Trans>cShop Market</Trans>
                  <img
                    alt=""
                    src={ExternalLinkIcon}
                    className="inline w-3.5 brightness-0 invert"
                  />
                </a>
                <a
                  href="https://www.ccart.io/"
                  target="_blank"
                  rel="noreferrer"
                  id="cta_footer_marketplace_in"
                  className="flex items-center gap-x-2"
                >
                  <Trans>cCart</Trans>
                  <img
                    alt=""
                    src={ExternalLinkIcon}
                    className="inline w-3.5 brightness-0 invert"
                  />
                </a>
              </FooterSection>
              <FooterSection title={trans("Company")}>
                <Link to="/who-we-serve/" id="cta_footer_whoWeServe_in">
                  <Trans>Who We Serve</Trans>
                </Link>
                <Link to="/about-us/" id="cta_footer_aboutUs_in">
                  <Trans>About us</Trans>
                </Link>
                <Link
                  to="/about-us/#locations"
                  id="cta_footer_ourLocations_in"
                  reloadDocument
                >
                  <Trans>Our Locations</Trans>
                </Link>
              </FooterSection>
              <FooterSection title={trans("Zbooni Hub")}>
                <a
                  href={ZBNI_HUB_URL}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center gap-x-2"
                  id="link_footer_zbooni_hub"
                  data-gtm-source="web"
                >
                  <Trans>Knowledge Base</Trans>
                  <img
                    alt=""
                    src={ExternalLinkIcon}
                    className="inline w-3.5 brightness-0 invert"
                  />
                </a>
                <Link to="/blog/" id="cta_footer_blogs_in">
                  <Trans>Blogs</Trans>
                </Link>
                <Link to="/partnerships/" id="cta_footer_partnerships_in">
                  <Trans>Partnerships</Trans>
                </Link>
                <Link to="/case-studies/" id="cta_footer_caseStudies_in">
                  <Trans>Case Studies</Trans>
                </Link>
              </FooterSection>
              <div className="flex flex-col gap-y-7">
                <FooterSection title={trans("Email us")}>
                  <div>
                    <a
                      className="bg-gradient-to-r from-cyan-dark to-yellowgreen bg-clip-text font-bold text-transparent"
                      href="mailto:hello@zbooni.com"
                      lang="en"
                    >
                      <span className="text-base">hello@zbooni.com</span>
                    </a>
                  </div>
                </FooterSection>
                <FooterSection title={trans("Contact us")}>
                  <p className="max-w-44 font-normal text-secondary ltr:text-11px rtl:text-sm">
                    <Trans>
                      We are always happy to answer any queries you have.
                    </Trans>
                  </p>
                  <a
                    href={`${ZBNI_CUSTOMER_SUPPORT}&text=Hello%21%20I%20am%20interested%20in%20learning%20more%20about%20Zbooni.`}
                    rel="noreferrer"
                    className="flex gap-x-2 w-40 items-center justify-center rounded-full bg-green-darkest p-1.5 text-xs text-primary"
                  >
                    <img className="w-5" src={WhatsAppLogo} alt="whatsapp" />
                    <span className="font-bold text-white">
                      <Trans>Get in touch</Trans>
                    </span>
                  </a>
                </FooterSection>
              </div>
            </div>
            <div className="hidden lg:flex lg:gap-x-3">
              <SecureTrustSection />
            </div>
          </div>
          <div className="flex flex-col gap-y-8 col-span-3 border-primary-light lg:gap-y-14 ltr:text-left rtl:text-right ltr:lg:border-l rtl:lg:border-r ltr:lg:pl-8 rtl:lg:pl-0 ltr:lg:pr-0 rtl:lg:pr-8 ltr:xl:pl-16 rtl:xl:pr-16">
            <FooterSection title={trans("Business Dashboard")}>
              <p className="mb-1 font-normal text-secondary ltr:text-xs rtl:text-sm">
                <Trans>Are you already on Zbooni?</Trans>
              </p>
              <DashboardLoginButton
                id="cta_footer_dashboardLogin"
                additionalClassNames="w-40 rounded-full bg-white p-2.5 text-xs font-black text-primary"
                iconColor="#282F3A"
                useShortDisplay={true}
              />
            </FooterSection>
            <hr className="w-full border-t border-primary-light" />
            <FooterSection title={trans("Find us on")}>
              <div className="flex gap-x-5 lg:gap-x-2.5 xl:gap-x-4">
                <a
                  href="https://www.facebook.com/ZbooniApp/"
                  target="_blank"
                  rel="noreferrer"
                  id="cta_footer_facebook"
                  className="flex items-center"
                >
                  <Sprite
                    name="facebook"
                    fill="#FFFFFF"
                    className="aspect-square w-8 mb-1"
                  />
                </a>
                <a
                  href="https://twitter.com/zbooni_app"
                  target="_blank"
                  rel="noreferrer"
                  id="cta_footer_twitter"
                  className="flex items-center"
                >
                  <Sprite
                    name="twitter"
                    fill="#FFFFFF"
                    className="aspect-square w-7 mb-1"
                  />
                </a>
                <a
                  href="https://www.instagram.com/zbooni_app/"
                  target="_blank"
                  rel="noreferrer"
                  id="cta_footer_instagram"
                  className="flex items-center"
                >
                  <Sprite
                    name="instagram"
                    fill="#FFFFFF"
                    className="aspect-square w-10 mb-1"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/zbooni/"
                  target="_blank"
                  rel="noreferrer"
                  id="cta_footer_linkedin"
                  className="flex items-center mb-2"
                >
                  <Sprite
                    name="linkedin"
                    fill="#FFFFFF"
                    className="aspect-square w-10"
                  />
                </a>
                <a
                  href={YTLink}
                  target="_blank"
                  rel="noreferrer"
                  id="cta_footer_youtube"
                  className="flex items-center"
                >
                  <Sprite
                    name="youtube"
                    fill="#FFFFFF"
                    className="aspect-square w-10"
                  />
                </a>
              </div>
            </FooterSection>
          </div>
        </div>
        <div className="flex pt-7 gap-x-3 lg:hidden">
          <SecureTrustSection />
        </div>
        <hr className="my-8 w-full border-t border-primary-light lg:hidden" />
        <div className="mb-5 space-y-3 pb-10 text-xs font-semibold lg:pb-0">
          <div className="text-secondary ltr:mr-3 rtl:ml-3 lg:inline">
            © {year}, <Trans>Zbooni</Trans>.&nbsp;
            <Trans>All rights reserved.</Trans>
          </div>
          <div className="lg:inline">
            <Link
              to="/terms/"
              className="inline-block hover:text-secondary"
              id="cta_footer_termsAndConditions_in"
            >
              <Trans>Terms and Conditions</Trans>
            </Link>
            <span lang="en" className="mx-2">
              |
            </span>
            <Link
              to="/data-privacy-policy/"
              className="hover:text-secondary"
              id="cta_footer_privacyPolicy_in"
            >
              <Trans>Privacy Policy</Trans>
            </Link>
            <span lang="en" className="mx-2">
              |
            </span>
            <Link
              to="/payment-policy/"
              className="hover:text-secondary"
              id="cta_footer_paymentPolicy_in"
            >
              <Trans>Payment Policy</Trans>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
